import ExternalAuthenticateComponent from 'components/Authentication/ExternalAuthenticateComponent';

const Authenticate = () => {
  return <ExternalAuthenticateComponent />;
};

Authenticate.theme = {
  navbar: {
    theme: 'light',
  },
};

export default Authenticate;
