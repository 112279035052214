import { useEffect } from 'react';

import { useAuth } from 'services/authClient';
import { useSharedDataActions, useSharedDataState } from 'store/sharedData';

import { useQueryParams } from '../../hooks/useQueryParams';

const ExternalAuthenticateComponent = () => {
  const query = useQueryParams();
  const { dispatch: sharedDispatch } = useSharedDataActions();
  const { isAuthOpen } = useSharedDataState();
  const { isAuthenticated, signoutWithoutRedirect } = useAuth();

  const redirectUrl =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.href).get('redirectUrl')
      : '';
  const needVerificationParameter = query.get('needVerification');
  const needVerification = needVerificationParameter === 'true';

  useEffect(() => {
    if (isAuthenticated) {
      signoutWithoutRedirect();
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !isAuthOpen) {
      sharedDispatch({
        isAuthOpen: true,
        needsVerifiedUser: needVerification,
        redirectUrl,
        redirectToHomePage: true,
      });
    }
  }, [isAuthenticated]);

  return null;
};

export default ExternalAuthenticateComponent;
